

























import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import ListSpan from '@/components/ListSpan/listSpan.vue'
import Constant from '@/constant/constant'
import { IAuditOrdersList } from '@/api/types'
import ExchangeApi from '@/api/exchange'
import DateUtil from '@/utils/date'

@Component({
  name: 'substrateInformation',
  computed: {
    DateUtil() {
      return DateUtil
    },
    Constant() {
      return Constant
    }
  },
  components: { ListSpan }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: '' }) private status!: 'checking' | 'pass' | 'fail' | 'ready'
  @Prop({ default: {} }) private auditOrdersList!: IAuditOrdersList
  @Prop({ default: '' }) private superviseId!: number

  private checkStatus = ''
  private endTime = ''
  private startTime = ''
  private startStatus = false

  @Watch('status', { immediate: true })
  private async onStatusChange(status: string) {
    console.log(this.auditOrdersList, 'auditOrdersList=====>')
    if (status === 'checking') {
      this.startStatus = true
      await this.checkExistenceProof()
      this.$emit('substrateResult', this.checkStatus)
    }
  }

  private async checkExistenceProof() {
    const { data } = await ExchangeApi.checkBottomAssets(this.superviseId)
    if (data) {
      this.checkStatus = data.proofResult ? 'pass' : 'fail'
      this.endTime = data.end
      this.startTime = data.start
    }
  }
}
