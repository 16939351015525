




























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'ListSpan'
})
export default class extends Vue {
  @Prop({ default: '' }) private leading!: string
  @Prop({ default: '' }) private tail!: string
  @Prop({ default: false }) private tailColor!: boolean
  @Prop({ default: true }) private tailUsually!: boolean
  @Prop({ default: false }) private tailIdentity!: boolean
  @Prop({ default: '' }) private tailName!: string
  @Prop({ default: '' }) private tailAddress!: string
  @Prop({ default: '' }) private tailId!: string
  @Prop({ default: false }) private tailAML!: boolean
  @Prop({ default: false }) private isAddressLink!: boolean
  @Prop({ default: false }) private isTransaction!: boolean
  @Prop({ default: false }) private isEvidence!: boolean
  @Prop({ default: '' }) private evidenceHex!: string

  private tailAMLArray = ['Specially Designated Nationals',
    'Foreign Sanctions Evaders',
    'Sectoral Sanctions ldentifications',
    'Palestinian Legislative Council',
    'Nonproliferation Sanctions',
    'Non-SDN Menu-Based Sanctions',
    'AECA Debarred',
    'Denied Persons List',
    'United Nations Consolidated Sanctions',
    'ClA List of Chiefs of State and Cabinet Members',
    'FBl Wanted List',
    'Consolidated List of Sanctions',
    'Interpol Red Notices for Wanted Persons',
    'World Bank Listing of ineligible Firms and Individuals',
    'lerrorists and lerrorist Entities',
    'Politically Exposed Persons']

  private addressLinkHref = 'http://47.238.179.208/address/'
  private transactionLinkHref = 'http://47.238.179.208/tx/'
  private existenceLinkHref = 'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fchainapi.ecaasospoc.com/#/explorer/query/'

  private handleLink() {
    if (this.isTransaction) {
      window.open(this.transactionLinkHref + this.tail, '_blank')
    } else if (this.isAddressLink) {
      window.open(this.addressLinkHref + this.tail, '_blank')
    } else if (this.isEvidence) {
      window.open(this.existenceLinkHref + this.evidenceHex, '_blank')
    }
  }
}
