
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query MyQuery($exchange_order_summary_id: Int!) {
    audit_orders(where: {exchange_order_summary_id: {_eq: $exchange_order_summary_id}}, limit: 1) {
      id
      existence_block_hash
      exchange_order_summary_id
      existence_check_result
      existence_check_start_at
      existence_check_end_at
      existence_tx_hash
      order_signature
      asset_transfer_tx_hash
      payment_tx_hash
      settlement_tx_hash
      order_check_result
      order_check_start_at
      order_check_end_at
      exchange_name
      rwa_token_id
      rwa_token_name
      rwa_token_type
      rwa_token_chain
      rwa_token_issuer
      order_amount
      currency_name
      currency_symbol
      currency_issuer
      seller_check_result
      seller_check_start_at
      seller_check_end_at
      seller_account
      seller_name
      seller_address
      seller_birthday
      seller_aml_proof_result
      buyer_check_result
      buyer_check_start_at
      buyer_check_end_at
      buyer_account
      buyer_name
      buyer_address
      buyer_birthday
      buyer_aml_proof_result
      bottom_asset_check_result
      bottom_asset_check_start_at
      bottom_asset_check_end_at
      cert_image
      asset_right_check_result
      asset_right_check_start_at
      asset_right_check_end_at
    }
  }
`

function fetchGetAuditOrdersQuery(exchangeOrderSummaryId: number) {
  return fetchGraphQL(operation, 'MyQuery', { exchange_order_summary_id: exchangeOrderSummaryId })
}

export default fetchGetAuditOrdersQuery
