import request from '@/utils/request'
import ApiConst from '@/constant/api'

export default class ExchangeApi {
  static checkAssetsRight = (id: number | undefined) =>
    request({
      url: ApiConst.CHECK_ASSETS_RIGHT,
      method: 'post',
      data: {
        id
      }
    })

  static checkBottomAssets = (id: number | undefined) =>
    request({
      url: ApiConst.CHECK_BOTTOM_ASSETS,
      method: 'post',
      data: {
        id
      }
    })

  static checkExistence = (id: number | undefined) =>
    request({
      url: ApiConst.CHECK_EXISTENCE_PROOF,
      method: 'post',
      data: {
        id
      }
    })

  static checkOrder = (id: number | undefined) =>
    request({
      url: ApiConst.CHECK_ORDER_PROOF,
      method: 'post',
      data: {
        id
      }
    })

  static checkSeller = (id: number | undefined) =>
    request({
      url: ApiConst.CHECK_SELLER_IDENTITY_PROOF,
      method: 'post',
      data: {
        id
      }
    })

  static checkBuyer = (id: number | undefined) =>
    request({
      url: ApiConst.CHECK_BUYER_IDENTITY_PROOF,
      method: 'post',
      data: {
        id
      }
    })

  static hexToUtf8 = (hexs:any[]) =>
    request({
      url: ApiConst.HEX_TO_UTF8,
      method: 'post',
      data: {
        hexs: hexs
      }
    })
}
