




























































































































































































import { Component, Vue } from 'vue-property-decorator'
import Container from '@/components/Container/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import CheckResultDrawer from '@/views/center/components/CheckResultDrawer.vue'
import ApplyResultDrawer from '@/views/center/components/ApplyResultDrawer.vue'
import CenterSearchHeader from '@/views/center/components/CenterSearchHeader.vue'
import fetchExchangeQuery from '@/api/husuraApi/getExchangeOrderSummaryApi'
import { UserModule } from '@/store/modules/user'
import UserService from '@/services/user'
import fetchGetAuditOrdersQuery from '@/api/husuraApi/getAuditOrdersApi'
import UserStorage from '@/storages/user'

@Component({
  name: 'Center',
  components: {
    Container,
    Pagination,
    CheckResultDrawer,
    ApplyResultDrawer,
    CenterSearchHeader
  }
})
export default class extends Vue {
  private page = 1
  private pageSize = 10
  private totalCount = 0
  private listLoading = false
  private isCheckResultDrawer = false
  private isApplyResultDrawer = false
  private exchangeItemsList = []
  private superviseInfo = {}
  private superviseItemId: number | undefined
  private linkHref = 'http://47.238.179.208/address/'
  private existenceLinkHref = 'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fchainapi.ecaasospoc.com/#/explorer/query/'

  created() {
    this.fetchCenterQuery()
  }

  private fetchCenterQuery(refresh = true) {
    if (refresh) {
      const token = UserModule.oauth.accessToken
      const jwt = UserService.decodeToken(token)
      let userDID
      if (jwt) {
        userDID = jwt.sub
      }
      if (!userDID) {
        this.$message.warning('未获取到DID信息，请重新登录')
        return
      }
      fetchExchangeQuery()
        .then(({ data: res }) => {
          const { data } = res
          this.exchangeItemsList = data.exchange_order_summary
          UserStorage.total = data.exchange_order_summary.length
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  private handleLink(info: any) {
    if (info.check_status === 'unchecked') {
      this.isCheckResultDrawer = true
      this.superviseItemId = info.id
    } else {
      this.isApplyResultDrawer = true
    }
    this.superviseInfo = info
  }

  private fetchCheckResultQuery(exchangeOrderSummaryId: any) {
    fetchGetAuditOrdersQuery(exchangeOrderSummaryId)
      .then(({ data: res }) => {
        const { data } = res
        if (data.audit_orders[0].bottom_asset_check_result && data.audit_orders[0].asset_right_check_result && data.audit_orders[0].existence_check_result && data.audit_orders[0].order_check_result && data.audit_orders[0].seller_check_result && data.audit_orders[0].buyer_check_result) {
          this.isCheckResultDrawer = false
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  private handleBeforeClose() {
    if (this.isCheckResultDrawer) {
      this.fetchCheckResultQuery(this.superviseItemId)
    }
  }

  private completedDate(dateAt: number) {
    const date = new Date(dateAt)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }
}
