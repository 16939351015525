/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query MyQuery($id: Int!) {
    exchange_order_summary_by_pk(id: $id) {
      id
      exchange_order {
        asset_transfer_tx_hash
        buyer_address
        buyer_aml_proof_hex
        buyer_eth_proof_hex
        buyer_identity_proof_hex
        buyer_message_hex
        buyer_message_signature
        buyer_time
        completed_at
        erc20_address
        erc20_decimals
        erc20_name
        erc20_symbol
        exchange_home_page
        existence_block_hash
        existence_tx_hash
        fee_rate
        id
        nft_id
        order_hex
        order_signature
        order_status
        payment_tx_hash
        price
        seller_address
        seller_aml_proof_hex
        seller_eth_proof_hex
        seller_identity_proof_hex
        seller_message_hex
        seller_message_signature
        seller_time
        settlement_tx_hash
        token_contract_address
        token_id
        nft {
        project_item {
          cert_image
        }
      }
      }
    }
  }
`

function fetchGetAuditOrdersByQuery(id: number) {
  return fetchGraphQL(operation, 'MyQuery', { id: id })
}

export default fetchGetAuditOrdersByQuery
