








































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import DepositInformation from '@/views/center/components/checkResult/depositInformation.vue'
import transactionInformation from '@/views/center/components/checkResult/transactionInformation.vue'
import sellerInformation from '@/views/center/components/checkResult/sellerInformation.vue'
import buyerInformation from '@/views/center/components/checkResult/buyerInformation.vue'
import Constant from '@/constant/constant'
import { IGetAuditOrdersQuery } from '@/api/types'
import fetchGetAuditOrdersByQuery from '@/api/husuraApi/getAuditOrdersByApi'
import ExchangeApi from '@/api/exchange'
import SubstrateInformation from '@/views/center/components/checkResult/substrateInformation.vue'
import AssestInformation from '@/views/center/components/checkResult/assestInformation.vue'

@Component({
  name: 'CheckResultDrawer',
  computed: {
    Constant() {
      return Constant
    }
  },
  components: { AssestInformation, SubstrateInformation, DepositInformation, transactionInformation, sellerInformation, buyerInformation }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: () => {
      return {
        superviseInfo: {}
      }
    }
  }) private initData!: {
    superviseInfo: IGetAuditOrdersQuery
  }

  private statusList = [
    'checking',
    'ready',
    'ready',
    'ready',
    'ready',
    'ready'
  ]

  private superviseInfo = {}
  private auditOrdersList = {
    id: '',
    exchange_order_summary_id: '',
    existence_check_result: '',
    existence_check_start_at: '',
    existence_check_end_at: '',
    existence_tx_hash: '',
    order_signature: '',
    asset_transfer_tx_hash: '',
    payment_tx_hash: '',
    settlement_tx_hash: '',
    order_check_result: '',
    order_check_start_at: '',
    order_check_end_at: '',
    exchange_name: '',
    rwa_token_id: '',
    rwa_token_name: '',
    rwa_token_type: '',
    rwa_token_chain: '',
    rwa_token_issuer: '',
    order_amount: '',
    currency_name: '',
    currency_symbol: '',
    currency_issuer: '',
    seller_check_result: '',
    seller_check_start_at: '',
    seller_check_end_at: '',
    seller_account: '',
    seller_name: '',
    seller_address: '',
    seller_birthday: '',
    seller_aml_proof_result: '',
    buyer_check_result: '',
    buyer_check_start_at: '',
    buyer_check_end_at: '',
    buyer_account: '',
    buyer_name: '',
    buyer_address: '',
    buyer_birthday: '',
    buyer_aml_proof_result: '',
    existence_block_hash: '',
    bottom_asset_check_result: '',
    bottom_asset_check_start_at: '',
    bottom_asset_check_end_at: '',
    cert_image: '',
    asset_right_check_result: '',
    asset_right_check_start_at: '',
    asset_right_check_end_at: '',
  }

  private superviseId: number | undefined
  private depositShow = false
  private assestShow = false
  private transactionShow = false
  private sellerShow = false
  private buyerShow = false
  private closeStatus = false

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.superviseInfo = this.initData.superviseInfo
      this.superviseId = this.initData.superviseInfo.id
      this.fetchApplyResultQuery(this.initData.superviseInfo.id)
    }
  }

  private fetchApplyResultQuery(exchangeOrderSummaryId: number) {
    fetchGetAuditOrdersByQuery(exchangeOrderSummaryId)
      .then(({ data: res }) => {
        const { data } = res
        const hexList = []
        this.auditOrdersList.existence_tx_hash = data.exchange_order_summary_by_pk.exchange_order.existence_tx_hash
        this.auditOrdersList.order_signature = data.exchange_order_summary_by_pk.exchange_order.order_signature
        this.auditOrdersList.asset_transfer_tx_hash = data.exchange_order_summary_by_pk.exchange_order.asset_transfer_tx_hash
        this.auditOrdersList.payment_tx_hash = data.exchange_order_summary_by_pk.exchange_order.payment_tx_hash
        this.auditOrdersList.settlement_tx_hash = data.exchange_order_summary_by_pk.exchange_order.settlement_tx_hash
        this.auditOrdersList.settlement_tx_hash = data.exchange_order_summary_by_pk.exchange_order.settlement_tx_hash
        this.auditOrdersList.rwa_token_id = data.exchange_order_summary_by_pk.exchange_order.token_id
        this.auditOrdersList.order_amount = data.exchange_order_summary_by_pk.exchange_order.price
        this.auditOrdersList.seller_account = data.exchange_order_summary_by_pk.exchange_order.seller_address
        this.auditOrdersList.buyer_account = data.exchange_order_summary_by_pk.exchange_order.buyer_address
        this.auditOrdersList.cert_image = data.exchange_order_summary_by_pk.exchange_order.nft.project_item.cert_image
        this.auditOrdersList.rwa_token_type = this.initData.superviseInfo.rwa_token_type
        this.auditOrdersList.rwa_token_issuer = this.initData.superviseInfo.rwa_token_issuer
        this.auditOrdersList.rwa_token_name = this.initData.superviseInfo.rwa_token_name
        this.auditOrdersList.rwa_token_name = this.initData.superviseInfo.rwa_token_name
        this.auditOrdersList.existence_block_hash = data.exchange_order_summary_by_pk.exchange_order.existence_block_hash
        this.auditOrdersList.exchange_name = '北京数字资产交易所'
        this.auditOrdersList.rwa_token_chain = 'Ethereum'
        this.auditOrdersList.currency_name = 'Tether USD'
        this.auditOrdersList.currency_symbol = 'USDT'
        this.auditOrdersList.currency_issuer = 'Tether'
        hexList.push(data.exchange_order_summary_by_pk.exchange_order.seller_identity_proof_hex)
        hexList.push(data.exchange_order_summary_by_pk.exchange_order.buyer_identity_proof_hex)
        this.hexToUtf8(hexList)
      })
      .catch(error => {
        console.error(error)
      })
  }

  private async hexToUtf8(hex: any[]) {
    const { data } = await ExchangeApi.hexToUtf8(hex)
    if (data) {
      const sellerContent = JSON.parse(data.utf8s[0])
      const buyerContent = JSON.parse(data.utf8s[1])
      this.auditOrdersList.seller_name = sellerContent.presentation.claim.contents.Name
      this.auditOrdersList.seller_birthday = sellerContent.presentation.claim.contents.Birth
      this.auditOrdersList.seller_address = sellerContent.presentation.claim.contents.Address
      this.auditOrdersList.buyer_name = buyerContent.presentation.claim.contents.Name
      this.auditOrdersList.buyer_birthday = buyerContent.presentation.claim.contents.Birth
      this.auditOrdersList.buyer_address = buyerContent.presentation.claim.contents.Address
    }
  }

  private close() {
    this.$emit('close', this.closeStatus)
  }
}
